import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      showNavbar: true, // 显示导航栏
    },
  },
  {
    path: '/self_setting',
    name: 'self_setting',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SelfSettingView.vue'),
    meta: {
      showNavbar: true, // 显示导航栏
    },
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    meta: {
      showNavbar: false, // 登录页不显示导航栏
    },
  },
  {
    path: '/blog',
    name: 'blog',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BlogView.vue'),
    meta: {
      showNavbar: true, // 显示导航栏
    },
  },
  {
    path: '/guest',
    name: 'guest',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/GuestView.vue'),
    meta: {
      showNavbar: false, // 显示导航栏
    },
    children: [
      {
        path: 'guest_home',
        name: 'guest_home',
        component: () => import(/* webpackChunkName: "about" */ '../components/GuestHomePage.vue'),
      },
      {
        path: 'blog_show',
        name: 'blog_show',
        component: () => import(/* webpackChunkName: "about" */ '../components/BlogShowPage.vue'),
        props:true
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
