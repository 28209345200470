<template>
  <el-container>
    <el-aside>
      <el-card shadow="always" style="margin-left: 20px;background: rgba(255,255,255,0.8)">
        <el-avatar :src="user_logo_src"></el-avatar>
        <div>
          <el-link :underline="false">克利夫兰程序员</el-link>
        </div>
        <br>
        <div>
          <el-tag v-for="(item,index) in user_tag_arr" :type="item.type" :key="index" class="user_tag">{{item.text}}</el-tag>
        </div>
        <el-divider></el-divider>
        <div>
          <el-timeline :reverse="false" style="padding: 0">
            <el-timeline-item v-for="(item, index) in timeline_arr" :key="index" :timestamp="item.timestamp" :type="item.type">
              {{item.content}}
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-card>
    </el-aside>
    <el-main style="padding-top: 0px">
      <el-card shadow="always" style="background: rgba(255,255,255,0.8)">
        <el-carousel :interval="5000" type="card" height="400px">
          <el-carousel-item v-for="(item,index) in carousel_arr" :key="index">
            <el-skeleton-item variant="image" style="width: 240px; height: 240px;" v-if="item.src==null&&item.src==''"/>
            <el-image :src="item.src" fit="cover" v-if="item.src!==null&&item.src!==''"></el-image>
            <h3>{{item.text}}</h3>
          </el-carousel-item>
        </el-carousel>
        <el-row style="margin-top: 10px">
          <el-col :span="7">
            <el-card>
              <el-statistic title="此时此刻"></el-statistic><el-skeleton :rows="1" v-if="!currentDateTime" /><h3 v-if="currentDateTime">{{currentDateTime}}</h3>
            </el-card>
          </el-col>
          <el-col :span="8" :offset="1">
            <el-card>
              <el-statistic title="当前温度"></el-statistic><el-skeleton :rows="1" v-if="!currentTemperature" /><h3 v-if="currentTemperature">{{currentTemperature}}</h3>
            </el-card>
          </el-col>
          <el-col :span="7" :offset="1">
            <el-card>
              <el-statistic title="当前天气"></el-statistic><el-skeleton :rows="1" v-if="!currentWeather" /><h3 v-if="currentWeather">{{currentWeather}}</h3>
            </el-card>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-card>
            <el-progress v-for="(item,index) in progress_arr" :key="index" :text-inside="true" :stroke-width="item.width" :percentage="item.percentage" :color="progressColors" :format="format_progress(item)" style="margin-bottom: 15px"></el-progress>
          </el-card>
        </el-row>
      </el-card>

    </el-main>
  </el-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import request from "@/utils/request";

@Component
export default class HomePage extends Vue {
  //data
  user_logo_src=require('../assets/user-logo/admin_logo.jpg');
  user_tag_arr=[{type:"danger",text:"共产主义者"},
    {type:"primary",text:"二次元"},
    {type:"success",text:"爱打篮球"},
    {type:"primary",text:"电子游戏中度爱好者"},
    {type:"danger",text:"做梦想当个作家"}];
  timeline_arr=[{timestamp:"2016-9",content:"邯郸学院嵌入式技术与应用专业入学",type:"success"},
    {timestamp:"2019-05",content:"北京星震同源数字系统股份有限公司实习",type:"success"},
    {timestamp:"2019-07",content:"北京星震同源数字系统股份有限公司实习结束",type:"success"},
    {timestamp:"2019-7",content:"邯郸学院嵌入式技术与应用专业毕业",type:"success"},
    {timestamp:"2019-9",content:"入职基成科技（天津）有限公司",type:"success"},
    {timestamp:"2021-03",content:"入学河北工程大学计算机科学与技术专业",type:"success"},
    {timestamp:"2023-07",content:"河北工程大学计算机科学与技术专业毕业",type:"success"},
    {timestamp:"2023-10",content:"基成科技（天津）有限公司离职",type:"primary"}];
  carousel_arr= [{src:require('../assets/home/carousel/36_white_4k.jpg'),text:"1"},
    {src:require('../assets/home/carousel/36_white_4k.jpg'),text:"2"},
    {src:require('../assets/home/carousel/36_white_4k.jpg'),text:"3"},
    {src:require('../assets/home/carousel/36_white_4k.jpg'),text:"4"},
    {src:require('../assets/home/carousel/36_white_4k.jpg'),text:"5"},
    {src:require('../assets/home/carousel/36_white_4k.jpg'),text:"6"}];
  currentDateTime : string='';
  currentTemperature : string='';
  currentWeather : string='';
  progress_arr=[{width:22,percentage:85,progress_status:"success",text:"HTML"},
    {width:22,percentage:50,progress_status:"format",text:"springboot"},
    {width:22,percentage:80,progress_status:"format",text:"Echarts"},
    {width:22,percentage:90,progress_status:"format",text:"vue"}];
  progressColors=[{color: '#f56c6c', percentage: 20},
    {color: '#e6a23c', percentage: 40},
    {color: '#5cb87a', percentage: 60},
    {color: '#1989fa', percentage: 80},
    {color: '#6f7ad3', percentage: 100}];
  mounted(): void{
    //更新时间
    this.updateCurrentDate();
    setInterval(() => {
      this.updateCurrentDate();
    }, 1000);
    //更新温度
    this.updateTemperatureAndWeather();
    setInterval(() => {
      this.updateTemperatureAndWeather();
    }, 3600000);
    this.loginStatus();
  };
  loginStatus():void {
    const login_status_string = localStorage.getItem("honey-user");
    if(login_status_string!=null){
      const login_status = JSON.parse(login_status_string);
      if(login_status){
        console.log(login_status);
      }
    }else{
      this.$router.push('/login')
    }
  };
  updateCurrentDate(): void {
    const currentDate= new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，所以需要加1
    const day = currentDate.getDate().toString().padStart(2, '0');
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');
    this.currentDateTime = year+'-'+month+'-'+day+' '+hours+':'+minutes+':'+seconds;
  };
  updateTemperatureAndWeather(): void {
    const apiKey = '202cc8512d7740b5bfc87fd60ae09214'; // 请替换为你的API密钥
    const city = '任丘'; // 河北省任丘市
    const city_url = `https://geoapi.qweather.com/v2/city/lookup?location=${city}&key=${apiKey}`;//获取city_id的url
    axios.get(city_url)
    .then(response => {
      const data = response.data;
      if(response.status==200&&data.code==200){
        const city_id = data.location[0].id;
        const city_url = `https://devapi.qweather.com/v7/weather/now?location=${city_id}&key=${apiKey}`;
        axios.get(city_url)
        .then(response => {
          const temperature = response.data.now.temp;
          this.currentTemperature = `当前${city}的温度是：${temperature}℃`
          const text = response.data.now.text
          const windDir  = response.data.now.windDir;
          const windScale   = response.data.now.windScale;
          const windSpeed   = response.data.now.windSpeed;
          this.currentWeather = `当前${city}的天气是：${text},${windDir}${windScale}级，风速${windSpeed}公里/小时`
        })
        .catch(error => {
          this.currentTemperature = '获取温度失败:'+error;
        });

      }
    })
    .catch(error => {
      this.currentTemperature = '获取温度失败:城市id无法获取'+error;
    });
  }

  format_progress(item:any){
    const text = item.text;
    const percentage = item.percentage;
    const getFormat = () => `${text}了解程度${percentage}%`;
    return getFormat;
  }

}
</script>

<style>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .user_tag{
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .el-progress__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 24px;
  }
</style>
