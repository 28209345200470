<template>
  <div class="home" >
    <HomePage/>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import HomePage from '@/components/HomePage.vue'; // @ is an alias to /src

@Component({
  components: {
    HomePage,
  },
})
export default class HomeView extends Vue {}
</script>
<style>
  .home{
    background-image: url("../assets/home/home_background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
  }
</style>
