<template>
  <div id="app">
    <el-menu :default-active="$route.path" mode="horizontal" :router="true" style="text-align: center" v-if="shouldShowNavbar">
      <el-menu-item index="/" >HOME</el-menu-item>
      <el-menu-item index="/self_setting">SETTING</el-menu-item>
      <el-menu-item index="/blog">BLOG</el-menu-item>
    </el-menu>

    <el-row id="main-body">
      <router-view style="padding: 15px;margin-bottom: 180px;min-height: 83vh;"/>
      <el-footer style="width: 100%">
        <el-row>
          <el-col :span="7">
            <el-row class="footer_head">About Me</el-row>
            <el-row>
              Comrade Pancrase Dai is a communist who did not join the party, he loves to play basketball, usually the third and fourth positions, but his throwing skills are not good, he loves to watch some anime, he dreamed of being a writer when he was a child, and when he grew up, he was slow to react and liked to play games
            </el-row>
          </el-col>
          <el-col :span="8" :offset="1">
            <el-row class="footer_head">Quick Links</el-row>
            <el-row style="margin-top: 10px;">
              <div v-bind:style="quick_links">
                <a href="https://space.bilibili.com/32435693">My Bilibili</a>
              </div>
              <div v-bind:style="quick_links">
                <a href="https://weibo.com/u/5316518027">My Weibo</a>
              </div>
            </el-row>
          </el-col>
          <el-col :span="7" :offset="1">
            <el-row class="footer_head">Contact Me</el-row>
            <el-row style="margin-top: 10px">
              <el-row>
                <i class="el-icon-phone"></i>+86 13833988217
              </el-row>
              <el-row style="padding-left: 14px">+86 18920255540</el-row>
            </el-row>
            <el-row style="margin-top: 10px">
              <i class="el-icon-message"></i>651370869@qq.com
            </el-row>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">Copyright © 2023 PancraseDai's Personal Website. All Rights Reserved.</el-row>
        <el-row style="margin-top: 10px">备案号：<a href="https://beian.miit.gov.cn/">冀ICP备2024065561号-1</a></el-row>
      </el-footer>
      <!--<el-backtop target=".page-component__scroll .el-scrollbar__wrap"></el-backtop>-->
    </el-row>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        shouldShowNavbar: false, // 默认显示导航栏
        quick_links:"margin-bottom:10px",//快速链接的样式
      };
    },
    created() {
      const login_status_string = localStorage.getItem("honey-user");
      if(login_status_string!=null){
        this.shouldShowNavbar = true;
      }else{
        this.shouldShowNavbar = false;
      }
    },
    watch: {
      $route(toRoute) {
        // 根据路由变化动态更新 shouldShowNavbar 的值
        this.shouldShowNavbar = toRoute.meta.showNavbar;
      },
    },
  }
  // 其他选项...
</script>

<style>

@font-face{
  font-family:"eva_font_b";
  src: url("./assets/font/FOT-MatissePro-M.otf");
}

@font-face{
  font-family:"eva_font_";
  src: url("./assets/font/FOT-MatissePro-UB.otf");
}

a{
  text-decoration:none;
  color: #66b1ff;
  margin-bottom: 10px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  width: 100%;

}

input::placeholder{
  font-family: eva_font_b;
}


.footer_head{
  font-size: 25px;
}

nav {
  padding: 30px;
  color: #333333;
  text-decoration: none;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
footer{
  padding:15px!important;
  color: #000;
  background-color: rgb(255,255,255);
  height: auto !important;
  position: absolute;
  bottom: 0;
}
</style>
