import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'font-awesome/css/font-awesome.css';

import '@/assets/css/global.css'
import request from "@/utils/request";



Vue.config.productionTip = false
Vue.use(ElementUI,{size: 'small'});

// @ts-ignore
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor);

/*import * as Quill from 'quill'
// @ts-ignore
import { ImageDrop } from 'quill-image-drop-module'
// @ts-ignore
Quill.register('modules/imageDrop', ImageDrop)*/

/*//实现quill-editor编辑器调整图片尺寸
// @ts-ignore
import ImageResize from 'quill-image-resize-module'
// @ts-ignore
Quill.register('modules/imageResize', ImageResize)*/



Vue.prototype.$request = request
Vue.prototype.$baseUrl = process.env.VUE_APP_BASEURL

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
